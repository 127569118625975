import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Modal, Checkbox, Table, Text, Button, Pagination } from '@pickme/design-system';
import { CollaboratorRoleV2, PollStatus } from '@pickme/core';

import Spinner from 'components/common/Spinner';
import PollSearchForm from 'components/features/poll-v2/form/Search';
import PollGroupBadge from 'components/features/poll-v2/badges/PollGroupBadge';

import { useGetPollSummaries } from 'query-hooks/poll-v2';

import { POLL_KIND_LABEL_V2 } from 'constants/poll-v2';

import { PollKindForSearchV2, PollSearchGroupV2, PollSearchQueryV2 } from 'types/poll-v2';

import { checkboxContainer, container, modal, table } from './index.css';

type Props = {
  isVisible: boolean;
  submittedPollIds: string[];
  onSubmit: (pollIds: string[]) => void;
  onClose: () => void;
};

function PollSelectModal({ isVisible, submittedPollIds, onClose, onSubmit }: Props) {
  const [selectedPollIds, setSelectedPollIds] = useState<string[]>([]);
  const [pollSearchQuery, setPollSearchQuery] =
    useState<PollSearchQueryV2>(DEFAULT_POLL_SEARCH_QUERY);

  const { data, isLoading } = useGetPollSummaries({
    kind: pollSearchQuery.kind,
    groups: pollSearchQuery.groups,
    status: PollStatus.Approved,
    page: pollSearchQuery.page,
    keyword: pollSearchQuery.keyword,
    hasVoterBook: true,
    roles: CollaboratorRoleV2.Editor,
  });

  const isTableEmpty = !isLoading && data && data.contents.length === 0;

  useEffect(() => {
    if (isVisible) {
      setPollSearchQuery(DEFAULT_POLL_SEARCH_QUERY);
      setSelectedPollIds(submittedPollIds);
    }

    return () => {
      setSelectedPollIds([]);
    };
  }, [isVisible]);

  return (
    <Modal className={modal} size='lg' isOpened={isVisible} onClose={onClose}>
      <Modal.Header>그룹 추가하기</Modal.Header>

      <Modal.Body className={container}>
        <PollSearchForm
          values={pollSearchQuery}
          onSubmit={(form) => setPollSearchQuery({ ...form, page: 1 })}
        />

        <Table scroll={{ vertical: true }} maxHeight={400}>
          <colgroup>
            <col width={44} />
            <col />
            <col />
            <col width={276} />
            <col width={65} />
            <col width={84} />
          </colgroup>

          <Table.Header>
            <Table.Tr>
              <Table.Th className={table.header}>
                <div className={checkboxContainer}>
                  <Checkbox
                    checked={
                      data?.contents.length !== 0 &&
                      !!data?.contents.every((poll) =>
                        selectedPollIds.find((selectedPollId) => selectedPollId === poll._id),
                      )
                    }
                    onChange={(event) => {
                      const pollData = data?.contents;
                      if (!pollData) {
                        return;
                      }

                      const pollDataIds = pollData.map((poll) => poll._id);
                      if (event.target.checked) {
                        setSelectedPollIds([...selectedPollIds, ...pollDataIds]);
                        return;
                      }

                      const filteredSelectedPolls = selectedPollIds.filter(
                        (selectedPollId) => !pollData.find((poll) => poll._id === selectedPollId),
                      );

                      setSelectedPollIds(filteredSelectedPolls);
                    }}
                  />
                </div>
              </Table.Th>
              <Table.Th className={table.header}>유형</Table.Th>
              <Table.Th className={table.header} textAlign='start'>
                제목
              </Table.Th>
              <Table.Th className={table.header} textAlign='start'>
                기간
              </Table.Th>
              <Table.Th className={table.header}>상태</Table.Th>
              <Table.Th className={table.header}>참여자 수</Table.Th>
            </Table.Tr>
          </Table.Header>

          <Table.Body>
            {isLoading && (
              <Table.Tr>
                <Table.Td colSpan={6}>
                  <Spinner />
                </Table.Td>
              </Table.Tr>
            )}

            {isTableEmpty ? (
              <Table.Tr>
                <Table.Td colSpan={6}>검색 결과가 없습니다.</Table.Td>
              </Table.Tr>
            ) : (
              data?.contents.map((poll) => (
                <Table.Tr key={poll._id} className={table.row}>
                  <Table.Td>
                    <div className={checkboxContainer}>
                      <Checkbox
                        checked={
                          !!selectedPollIds.find((selectedPollId) => selectedPollId === poll._id)
                        }
                        onChange={(event) => {
                          const copiedSelectedPollIds = selectedPollIds.slice();
                          if (event.target.checked) {
                            copiedSelectedPollIds.push(poll._id);
                          } else {
                            const index = selectedPollIds.findIndex(
                              (selectedPollId) => selectedPollId === poll._id,
                            );
                            copiedSelectedPollIds.splice(index, 1);
                          }

                          setSelectedPollIds(copiedSelectedPollIds);
                        }}
                      />
                    </div>
                  </Table.Td>

                  <Table.Td>
                    <Text size={14} fontWeight={500}>
                      {POLL_KIND_LABEL_V2[poll.kind]}
                    </Text>
                  </Table.Td>

                  <Table.Td textAlign='start'>{poll?.name}</Table.Td>

                  <Table.Td textAlign='start'>
                    {dayjs(poll.startDate).format('YY.MM.DD(dd) HH:mm')}
                    {' ~ '}
                    {poll.noEndDate
                      ? '종료 시까지'
                      : dayjs(poll.endDate).format('YY.MM.DD(dd) HH:mm')}
                  </Table.Td>

                  <Table.Td>
                    <PollGroupBadge group={poll.group} />
                  </Table.Td>

                  <Table.Td>{poll.population?.count || 0}명</Table.Td>
                </Table.Tr>
              ))
            )}
          </Table.Body>
        </Table>

        <Pagination
          currentPage={pollSearchQuery.page || 1}
          totalPage={data?.endPage || 1}
          onMove={(targetPage) => {
            setPollSearchQuery({
              ...pollSearchQuery,
              page: targetPage,
            });
          }}
        />
      </Modal.Body>

      {selectedPollIds.length > 0 && (
        <Modal.Action>
          <Button fullWidth onClick={() => onSubmit(selectedPollIds)} disabled={isLoading}>
            추가하기
          </Button>
        </Modal.Action>
      )}
    </Modal>
  );
}

export default PollSelectModal;

const DEFAULT_POLL_SEARCH_QUERY: PollSearchQueryV2 = {
  page: 1,
  keyword: '',
  kind: PollKindForSearchV2.All,
  groups: PollSearchGroupV2.All,
};
