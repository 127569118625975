import { SocialTitleV2 } from '@pickme/core';

export type ChannelQueryType = 'alias' | 'objectId';

export type ChannelEditForm = {
  name: string;
  alias: string;
  logo: string;
  cover: string;
  description: string;
  socials: {
    platform: SocialTitleV2;
    url: string;
  }[];
};

export type ChannelCreateForm = {
  name: string;
  alias: string;
  logo?: string;
  cover?: string;
};

export enum ChannelFormStep {
  Name = 'name',
  Alias = 'alias',
  Image = 'image',
  Complete = 'complete',
}

export type ChannelTransferForm = {
  managerId: string;
  email: string;
};

export type ChannelTransferRequestBody = {
  email: string;
};

export type ChannelAcceptForm = {
  channelId: string;
  authKey: string;
  customerKey: string;
};

export type TransferInfoResponse = {
  _id: string;
  id: string;
  organizationOid: string;
  email: string;
  requestedAt: string;
  expiresAt: string;
  isCardRegistrationRequired: boolean;
  customerKey: string;
};

export type TransferInfo = {
  _id: string;
  id: string;
  channelId: string;
  email: string;
  requestedAt: string;
  expiresAt: string;
  isCardRegistrationRequired: boolean;
  customerKey: string;
};

export type TransferredChannelResponse = {
  _id: string;
  channelId: string;
  email: string;
  requestedAt: string;
  accountToTransfer: {
    _id: string;
    point: number;
    bonus: number;
    ownerId: string;
    channelId: string;
  } | null;
};
