import { CollaboratorRoleV2, ManagerRoleTitleV2, PlanTitleV2 } from '@pickme/core';

import {
  FeatureAction,
  RoleToActionMap,
  FeatureTitleV2,
  SubscriptionAction,
  PlanToFeatureMap,
  MessagingAction,
  ManagerAction,
  VoterSeatAction,
  IntegrationAction,
  CollaboratorAction,
  ApplicationAction,
  CollaboratorRoleToActionMap,
} from 'types/features-v2';

const ROLE_TO_FEATURE_MAP: RoleToActionMap = {
  [FeatureTitleV2.구독및결제]: {
    [ManagerRoleTitleV2.Admin]: Object.values(SubscriptionAction),
    [ManagerRoleTitleV2.Editor]: [SubscriptionAction.구독페이지진입],
    [ManagerRoleTitleV2.Viewer]: [SubscriptionAction.구독페이지진입],
  },
  [FeatureTitleV2.메시지]: {
    [ManagerRoleTitleV2.Admin]: Object.values(MessagingAction),
    [ManagerRoleTitleV2.Editor]: Object.values(MessagingAction),
    [ManagerRoleTitleV2.Viewer]: Object.values(MessagingAction),
  },
  [FeatureTitleV2.매니저]: {
    [ManagerRoleTitleV2.Admin]: Object.values(ManagerAction),
    [ManagerRoleTitleV2.Editor]: [ManagerAction.페이지진입],
    [ManagerRoleTitleV2.Viewer]: [ManagerAction.페이지진입],
  },
  [FeatureTitleV2.참여자인증]: {
    [ManagerRoleTitleV2.Admin]: Object.values(VoterSeatAction),
    [ManagerRoleTitleV2.Editor]: [],
    [ManagerRoleTitleV2.Viewer]: [],
  },
  [FeatureTitleV2.연동]: {
    [ManagerRoleTitleV2.Admin]: Object.values(IntegrationAction),
    [ManagerRoleTitleV2.Editor]: [],
    [ManagerRoleTitleV2.Viewer]: [],
  },
  [FeatureTitleV2.공동작업자]: {
    [ManagerRoleTitleV2.Admin]: Object.values(CollaboratorAction),
    [ManagerRoleTitleV2.Editor]: Object.values(CollaboratorAction),
    [ManagerRoleTitleV2.Viewer]: [CollaboratorAction.페이지진입],
  },
  [FeatureTitleV2.만들기]: {
    [ManagerRoleTitleV2.Admin]: Object.values(ApplicationAction),
    [ManagerRoleTitleV2.Editor]: Object.values(ApplicationAction),
    [ManagerRoleTitleV2.Viewer]: [ApplicationAction.페이지진입],
  },
};

const PLAN_TO_FEATURE_MAP: PlanToFeatureMap = {
  [FeatureTitleV2.구독및결제]: {
    [PlanTitleV2.Basic]: Object.values(SubscriptionAction),
    [PlanTitleV2.Pro]: Object.values(SubscriptionAction),
    [PlanTitleV2.Custom]: Object.values(SubscriptionAction),
  },
  [FeatureTitleV2.메시지]: {
    [PlanTitleV2.Basic]: [],
    [PlanTitleV2.Pro]: Object.values(MessagingAction),
    [PlanTitleV2.Custom]: Object.values(MessagingAction),
  },
  [FeatureTitleV2.매니저]: {
    [PlanTitleV2.Basic]: [],
    [PlanTitleV2.Pro]: Object.values(ManagerAction),
    [PlanTitleV2.Custom]: Object.values([
      ManagerAction.페이지진입,
      ManagerAction.매니저수정,
      ManagerAction.매니저초대,
    ]),
  },
  [FeatureTitleV2.참여자인증]: {
    [PlanTitleV2.Basic]: [],
    [PlanTitleV2.Pro]: Object.values(VoterSeatAction),
    [PlanTitleV2.Custom]: [],
  },
  [FeatureTitleV2.연동]: {
    [PlanTitleV2.Basic]: [],
    [PlanTitleV2.Pro]: Object.values(IntegrationAction),
    [PlanTitleV2.Custom]: Object.values(IntegrationAction),
  },
  [FeatureTitleV2.공동작업자]: {
    [PlanTitleV2.Basic]: [],
    [PlanTitleV2.Pro]: Object.values(CollaboratorAction),
    [PlanTitleV2.Custom]: Object.values(CollaboratorAction),
  },
  [FeatureTitleV2.만들기]: {
    [PlanTitleV2.Basic]: [
      ApplicationAction.페이지진입,
      ApplicationAction.만들기,
      ApplicationAction.수정하기,
      ApplicationAction.삭제하기,
    ],
    [PlanTitleV2.Pro]: Object.values(ApplicationAction),
    [PlanTitleV2.Custom]: Object.values(ApplicationAction),
  },
};

const COLLABORATOR_ROLE_TO_FEATURE_MAP: CollaboratorRoleToActionMap = {
  [FeatureTitleV2.구독및결제]: {
    [CollaboratorRoleV2.Admin]: Object.values(SubscriptionAction),
    [CollaboratorRoleV2.Editor]: Object.values(SubscriptionAction),
    [CollaboratorRoleV2.Viewer]: Object.values(SubscriptionAction),
  },
  [FeatureTitleV2.메시지]: {
    [CollaboratorRoleV2.Admin]: Object.values(MessagingAction),
    [CollaboratorRoleV2.Editor]: Object.values(MessagingAction),
    [CollaboratorRoleV2.Viewer]: Object.values(MessagingAction),
  },
  [FeatureTitleV2.매니저]: {
    [CollaboratorRoleV2.Admin]: Object.values(ManagerAction),
    [CollaboratorRoleV2.Editor]: Object.values(ManagerAction),
    [CollaboratorRoleV2.Viewer]: Object.values(ManagerAction),
  },
  [FeatureTitleV2.참여자인증]: {
    [CollaboratorRoleV2.Admin]: Object.values(VoterSeatAction),
    [CollaboratorRoleV2.Editor]: Object.values(VoterSeatAction),
    [CollaboratorRoleV2.Viewer]: Object.values(VoterSeatAction),
  },
  [FeatureTitleV2.연동]: {
    [CollaboratorRoleV2.Admin]: Object.values(IntegrationAction),
    [CollaboratorRoleV2.Editor]: Object.values(IntegrationAction),
    [CollaboratorRoleV2.Viewer]: Object.values(IntegrationAction),
  },
  [FeatureTitleV2.공동작업자]: {
    [CollaboratorRoleV2.Admin]: Object.values(CollaboratorAction),
    [CollaboratorRoleV2.Editor]: Object.values(CollaboratorAction),
    [CollaboratorRoleV2.Viewer]: [CollaboratorAction.페이지진입],
  },
  [FeatureTitleV2.만들기]: {
    [CollaboratorRoleV2.Admin]: Object.values(ApplicationAction),
    [CollaboratorRoleV2.Editor]: [
      ApplicationAction.페이지진입,
      ApplicationAction.엔딩,
      ApplicationAction.명부,
      ApplicationAction.만들기,
      ApplicationAction.수정하기,
      ApplicationAction.비공개설정,
    ],
    [CollaboratorRoleV2.Viewer]: [ApplicationAction.페이지진입],
  },
};

export class Permissions {
  static isChannelRoleAllowedToAction<T extends FeatureTitleV2>({
    role,
    featureName,
    actionName,
  }: {
    role: ManagerRoleTitleV2;
    featureName: T;
    actionName: FeatureAction[T];
  }): boolean {
    const actions = ROLE_TO_FEATURE_MAP[featureName][role] as FeatureAction[T][];
    return actions ? actions.includes(actionName) : false;
  }

  static isPlanAllowedToFeature<T extends FeatureTitleV2>({
    plan,
    featureName,
    actionName,
  }: {
    plan: PlanTitleV2;
    featureName: T;
    actionName: FeatureAction[T];
  }): boolean {
    const actions = PLAN_TO_FEATURE_MAP[featureName][plan] as FeatureAction[T][];
    return actions ? actions.includes(actionName) : false;
  }

  static isCollaboratorRoleAllowedToAction<T extends FeatureTitleV2>({
    role,
    featureName,
    actionName,
  }: {
    role: CollaboratorRoleV2;
    featureName: T;
    actionName: FeatureAction[T];
  }): boolean {
    const actions = COLLABORATOR_ROLE_TO_FEATURE_MAP[featureName][role] as FeatureAction[T][];
    return actions ? actions.includes(actionName) : false;
  }
}
