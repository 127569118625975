export enum ApplicationMode {
  Write = 'application',
  Edit = 'edit',
  Draft = 'draft',
}

export enum ApplicationStepV2 {
  Intro = 'intro',
  Main = 'main',
  Ending = 'ending',
  Settings = 'settings',
  VoterBook = 'voterbook',
}

export type Cover = {
  url: string;
  type: CoverMediaType;
};

export enum CoverMediaType {
  Image = 'image',
  Video = 'video',
}

export enum ThemeType {
  Preset = 'preset',
  Custom = 'custom',
}

export enum ThemeNames {
  White = 'white',
  Yellow = 'yellow',
  Orange = 'orange',
  Red = 'red',
  Pink = 'pink',
  Violet = 'violet',
  Lime = 'lime',
  Green = 'green',
  Brown = 'brown',
  Indigo = 'indigo',
  Blue = 'blue',
  Gray = 'gray',
}

export enum ElectionTypeCase {
  선택투표 = '선택투표',
  찬반투표 = '찬반투표',
  순위투표 = '순위투표',
  점수투표 = '점수투표',
}

export type CandidateMeta = {
  id?: string;
  title: string;
  imageUrls?: {
    main?: string;
    others?: (string | undefined)[];
  };
};

export type Person = {
  id?: string;
  name?: string;
  department?: {
    enabled?: boolean;
    value?: string;
  };
  position?: {
    enabled?: boolean;
    value?: string;
  };
  biography?: {
    enabled?: boolean;
    value?: {
      date?: string;
      description?: string;
    }[];
  };
  imageUrl?: {
    enabled?: boolean;
    value?: string;
  };
};

export type Candidate = CandidateMeta & {
  members?: Person[] | null;
  pledges?: (string | undefined)[];
  introduction?: string;
  social?: {
    facebook?: string;
    instagram?: string;
    youtube?: string;
    blog?: string;
    website?: string;
  };
};

export type CandidateElectionItem = {
  key?: string;
  type: ElectionTypeCase;
  title: string;
  description?: string;
  imageUrl?: string;
  candidates: Candidate[];
  includeAbstention?: boolean;
  allowMultipleSelectionEnabled?: boolean;
  allowMultipleSelectionMinValue?: number;
  allowMultipleSelectionMaxValue?: number;
  minScore?: number;
  maxScore?: number;
  rank?: number;
  rankWeights?: (number | undefined)[];
  shuffle?: boolean;
};

export type IssueElectionItem = {
  key?: string;
  type: ElectionTypeCase;
  title: string;
  description?: string;
  imageUrl?: string;
  candidates: CandidateMeta[];
  includeAbstention?: boolean;
  allowMultipleSelectionEnabled?: boolean;
  allowMultipleSelectionMinValue?: number;
  allowMultipleSelectionMaxValue?: number;
  minScore?: number;
  maxScore?: number;
  rank?: number;
  rankWeights?: (number | undefined)[];
  shuffle?: boolean;
};

export enum PeriodActionType {
  Instantly = 'instantly',
  Custom = 'custom',
}

export enum VoterType {
  All = 'all',
  Password = 'password',
  VoterBook = 'voterBook',
}

export type PeriodValue = {
  startDate: Date | null;
  isStartDateDisabled?: boolean;
  endDate: Date | null;
  isEndDateDisabled?: boolean;
};
export type PeriodGroup = { id: string; isRegistered?: boolean; values: PeriodValue[] };

export enum AuthType {
  OTP = 'otp',
  Pass = 'pass',
}

export enum AuthMethod {
  Email = 'email',
  PhoneNumber = 'phoneNumber',
}

export enum SurveyTypeCase {
  객관식 = '객관식',
  주관식 = '주관식',
  이미지선택 = '이미지선택',
  동영상선택 = '동영상선택',
  선호도평가 = '선호도평가',
  점수평가 = '점수평가',
  선형배율 = '선형배율',
  별점 = '별점',
  드롭다운 = '드롭다운',
  이메일주소 = '이메일주소',
  휴대폰번호 = '휴대폰번호',
  날짜 = '날짜',
  시간 = '시간',
  링크 = '링크',
  개인정보수집 = '개인정보수집',
}

export enum SurveyTypeCategory {
  문제유형 = '문제 유형',
  정보입력유형 = '정보입력유형',
  개인정보수집동의 = '개인정보수집동의',
}

export type SurveyPage = {
  id?: string;
  title: string;
  description?: string;
  questions: Question[];
};

export type Question = {
  id: string;
  type: SurveyTypeCase;
  title?: string;
  description?: string;
  imageUrl?: string;
  answers?: Answer[];
  allowMultipleSelectionEnabled?: boolean;
  allowMultipleSelectionMinValue?: number;
  allowMultipleSelectionMaxValue?: number;
  minScore?: number;
  maxScore?: number;
  scaleMinValue?: number;
  scaleMinLabel?: string;
  scaleMaxValue?: number;
  scaleMaxLabel?: string;
  starTotal?: number;
  starUnit?: number;
  rank?: number;
  rankWeights?: (number | undefined)[];
  isLong?: boolean;
  shuffle?: boolean;
  required?: boolean;
  privacyAgreementsItems?: string;
  privacyAgreementsPurpose?: string;
  privacyAgreementsPeriod?: string;
};

export type Answer = {
  value?: string;
  id: string;
  imageUrl?: string;
  videoUrl?: string;
};

export type VoterFields = {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  additionalFields?: string[];
  hasVoted?: boolean;
};

export type InvalidVoterFields = {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  additionalFields?: string[];
  hasVoted?: boolean;
  invalidFields?: ('name' | 'email' | 'phoneNumber')[];
};
